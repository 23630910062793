import '@popperjs/core';
import '@popperjs/core';
import 'bootstrap';

import Cookies from 'js-cookie';

import { COOKIES } from '@/shared/cookies';

import { setDeviceId } from '@/front/device';
import '@/front/resize';
import '@/front/tooltip';

document.addEventListener('DOMContentLoaded', () => {
  setDeviceId();

  window.addEventListener('scroll', () => {
    document.body.classList.toggle('scrolled', window.scrollY >= 50);
  });

  const planIntervalSwitch = document.getElementById('plan-interval-switch') as HTMLInputElement | undefined;

  const plansMonth = document.getElementById('plans-month') as HTMLDivElement | undefined;
  const plansYear = document.getElementById('plans-year') as HTMLDivElement | undefined;

  if (planIntervalSwitch && plansMonth && plansYear) {
    planIntervalSwitch.addEventListener('click', () => {
      plansMonth.classList.toggle('active', !planIntervalSwitch.checked);
      plansYear.classList.toggle('active', planIntervalSwitch.checked);
    });
  }

  const cookieConsentDiv = document.getElementById('cookie-consent');

  if (cookieConsentDiv) {
    const cookieConsent = Cookies.get(COOKIES.COOKIE_CONSENT.name) === 'true';

    if (!cookieConsent) {
      cookieConsentDiv.classList.remove('d-none');

      const cookieConsentButton = cookieConsentDiv.querySelector('.ok.btn') as HTMLButtonElement;

      cookieConsentButton.addEventListener('click', () => {
        Cookies.set(COOKIES.COOKIE_CONSENT.name, 'true');

        cookieConsentDiv.classList.add('d-none');
      });
    }
  }
});
